import React, { Component } from "react";
import "./App.css";
import providers from "./2020providers.json";

class App extends Component {
  state = { enteredText: "" };

  textChanged = (args) => {
    this.setState({ enteredText: args.target.value });
  };

  render() {
    var providerList = providers
      .filter((x) => x.PIN === this.state.enteredText)
      .map((provider) => {
        let address = [];
        if ("Address2" in provider && provider.Address2 !== "") {
          address.push(
            <span key="line2">
              {provider.Address2}
              <br />
            </span>
          );
        }
        address.push(
          <span key="line3">
            {provider.City} {provider.State}, {provider.Zip}
          </span>
        );
        return (
          <div
            className="ProviderInfo"
            key={
              provider.PIN +
              provider.Address +
              provider.Address2 +
              provider.FirstName +
              provider.LastName
            }
          >
            <span className="Label">Name:</span> {provider.FirstName}{" "}
            {provider.MiddleName} {provider.LastName} <br />
            <span className="Label">Phone:</span> {provider.PhoneNumber}
            <br />
            <span className="Label">Address:</span> <br />
            {provider.Address} <br />
            {address}
          </div>
        );
      });

    return (
      <div className="Container">
        <h1>TRS Provider Lookup by Primary Care Physician (PCP) Code</h1>
        Input PCP Code:{" "}
        <input
          type="text"
          onChange={this.textChanged}
          value={this.state.enteredText}
        ></input>
        {providerList}
      </div>
    );
  }
}

export default App;
